import axios, { CancelToken, CancelTokenSource } from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getSupportedPaymentTypes } from '@apis/flight';

import { getCurrentSiteState, getLocaleState } from '@redux/selectors';

const useAvailablePaymentMethods = () => {
  const [availablePaymentMethods, setAvailablePaymentMethods] = useState<
    Array<HomepageFlightsPaymentMethod>
  >([]);

  const apiBaseUrl = API_BASE_URL;
  const locale = useSelector(getLocaleState);
  const currentSite = useSelector(getCurrentSiteState);

  useEffect(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const getSupportedPaymentMethods = async (cancelToken: CancelToken): Promise<void> => {
      try {
        const supportedPaymentTypes = await getSupportedPaymentTypes(
          apiBaseUrl,
          locale,
          currentSite.countryCode,
          cancelToken,
        );

        const sortedPaymentMethods = supportedPaymentTypes.sort(
          (aPaymentMethod, bPaymentMethod) => {
            if (aPaymentMethod.isPopular && !bPaymentMethod.isPopular) {
              return -1;
            } else if (!aPaymentMethod.isPopular && bPaymentMethod.isPopular) {
              return 1;
            }
            return 0;
          },
        );

        setAvailablePaymentMethods(sortedPaymentMethods);
      } catch (_) {}
    };

    getSupportedPaymentMethods(cancelTokenSource.token);
  }, [currentSite.countryCode, locale]);

  return { availablePaymentMethods };
};

export default useAvailablePaymentMethods;
