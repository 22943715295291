import { SegmentAncillaries } from '@pages/FlightBookingPage/types';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { getAncillaries } from '@apis/flight';

import { useAxiosQuery } from '@hooks/useAxiosQuery';

import useSeatSelectionFeatureFlag from './useSeatSelectionFeatureFlag';

export const getAncillariesKey = 'flight-bow-acilaries';

export const useGetAncillaries = () => {
  const { fareCode = '' } = useParams<{
    fareCode: string;
  }>();
  const isOnSeatSelectionFeatureFlag = useSeatSelectionFeatureFlag();
  const { data } = useAxiosQuery<SegmentAncillaries[]>({
    queryKey: [getAncillariesKey, fareCode],
    queryFn: () => getAncillaries(fareCode || ''),
    enabled: !!(isOnSeatSelectionFeatureFlag && fareCode),
  });

  const isSeatSelectionAvailable = useMemo(
    () => data?.some((segment) => segment.seatSelectionSupported) || false,
    [data],
  );

  return {
    isSeatSelectionAvailable,
    seatSelectionAncillaries: data,
  };
};
